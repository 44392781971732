import React from 'react';
import { Layout } from 'antd';
import { Route, Routes, useLocation } from 'react-router-dom';
import Topbar from './components/dashboard/Topbar';
import Sidebar from './components/dashboard/Sidebar';
import AppFooter from './components/dashboard/Footer';
import { Content } from 'antd/es/layout/layout';
import Dashboard from './pages/Dashboard';
import School from './pages/School';
import Projects from './pages/Projects';
import Admin from './pages/Admin';
import Auth from './pages/Auth';
import NotFound from './pages/NotFound';
import PrivateRoute from './components/PrivateRoute';
import LandingPage from './pages/LandingPage';
import Submissions from "./pages/Submissions";
import SchoolVerification from "./components/school/SchoolVerification";
import SchoolRegistrationPage from './pages/SchoolRegistrationPage';

function App() {
    const location = useLocation();
    const isLoginPage = location.pathname === '/portal/login';
    const isNotFoundPage = location.pathname === '/404';
    const isLandingPage = location.pathname === '/';
    const isPortalRoute = location.pathname.startsWith('/portal');
    const isRegistrationPage = location.pathname === '/school-registration';

    return (
        <div className="App">
            {isLandingPage ? (
                <Routes>
                    <Route exact path="/" element={<LandingPage />} />
                </Routes>
            ) : isPortalRoute ? (
                <Layout style={{ minHeight: '100vh' }}>
                    {!isLoginPage && <Topbar />}
                    <Layout>
                        {!isLoginPage && !isNotFoundPage && <Sidebar />}
                        <Layout style={{ padding: '0 24px 24px' }}>
                            <Content
                                className="site-layout-background"
                                style={{
                                    padding: 24,
                                    margin: 0,
                                    minHeight: 280,
                                }}
                            >
                                <Routes>
                                    <Route exact path="/portal/login" element={<Auth />} />
                                    <Route
                                        exact
                                        path="/portal"
                                        element={
                                            <PrivateRoute>
                                                <Dashboard />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/portal/dashboard"
                                        element={
                                            <PrivateRoute>
                                                <Dashboard />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/portal/school"
                                        element={
                                            <PrivateRoute>
                                                <School />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/portal/projects"
                                        element={
                                            <PrivateRoute>
                                                <Projects />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/portal/submission"
                                        element={
                                            <PrivateRoute>
                                                <Submissions />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/portal/pending-schools"
                                        element={
                                            <PrivateRoute>
                                                <SchoolVerification />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/portal/admins"
                                        element={
                                            <PrivateRoute>
                                                <Admin />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route path="/*" element={<NotFound />} />
                                </Routes>
                            </Content>
                            {!isLoginPage && <AppFooter />}
                        </Layout>
                    </Layout>
                </Layout>
            ) : isRegistrationPage ? (
                <Routes>
                    <Route exact path="/school-registration" element={<SchoolRegistrationPage />} />
                </Routes>
            ) : (
                <Routes>
                    <Route path="/*" element={<NotFound />} />
                </Routes>
            )}
        </div>
    );
}

export default App;
