import React, { useState, useEffect } from 'react';
import { Card, Table, Button, Drawer, message } from 'antd';
import { EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import AddProjects from '../components/project/AddProjects';
import ViewProject from '../components/project/ViewProject';
import { getProjects, deleteProject } from '../context/dbTransactions/projects';

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [currentProject, setCurrentProject] = useState(null);
  const [drawerContent, setDrawerContent] = useState(null);

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    try {
      const data = await getProjects();
      setProjects(data);
    } catch (error) {
      message.error('Failed to fetch projects.');
    }
  };

  const showDrawer = (content, project = null) => {
    setCurrentProject(project);
    setDrawerContent(content);
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
    setCurrentProject(null);
    setDrawerContent(null);
  };

  const handleEdit = (record) => {
    showDrawer('edit', record);
  };

  const handleDelete = async (record) => {
    try {
      await deleteProject(record._id);
      message.success('Project deleted successfully.');
      fetchProjects();
    } catch (error) {
      message.error('Failed to delete project.');
    }
  };

  const handleView = (record) => {
    showDrawer('view', record);
  };

  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
    },
    {
      title: 'Phase',
      dataIndex: 'phase',
      key: 'phase',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <span>
          <Button icon={<EyeOutlined />} style={{ marginRight: 8 }} onClick={() => handleView(record)} />
          <Button icon={<EditOutlined />} style={{ marginRight: 8 }} onClick={() => handleEdit(record)} />
          <Button icon={<DeleteOutlined />} style={{ marginRight: 8 }} onClick={() => handleDelete(record)} />
        </span>
      ),
    },
  ];

  return (
    <div>
      <Card
        title="Projects"
        extra={<Button type="primary" onClick={() => showDrawer('edit')}>Add Project</Button>}
      >
        <Table columns={columns} dataSource={projects} rowKey="_id" />
      </Card>
      <Drawer
        title={currentProject ? (drawerContent === 'edit' ? "Edit Project" : "View Project") : "Add Project"}
        width={720}
        onClose={closeDrawer}
        visible={drawerVisible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        {drawerContent === 'edit' ? (
          <AddProjects project={currentProject} onClose={closeDrawer} />
        ) : (
          <ViewProject project={currentProject} />
        )}
      </Drawer>
    </div>
  );
};

export default Projects;
