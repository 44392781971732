import React, { useEffect, useState } from 'react';
import { Card, Button, message, Spin } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const SubmittedVanVisits = () => {
  const [vanVisits, setVanVisits] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVanVisits = async () => {
      try {
        const response = await axios.get('/api/van-visits'); // Adjust the endpoint as needed
        setVanVisits(response.data);
      } catch (error) {
        message.error('Failed to fetch van visits');
      } finally {
        setLoading(false);
      }
    };

    fetchVanVisits();
  }, []);

  const handleReview = (vanVisitId) => {
    navigate(`/portal/van-visits/${vanVisitId}`);
  };

  const handleReject = async (vanVisitId) => {
    try {
      await axios.post(`/api/van-visits/${vanVisitId}/reject`);
      message.success('Van visit rejected');
      setVanVisits(vanVisits.filter(vanVisit => vanVisit._id !== vanVisitId));
    } catch (error) {
      message.error('Failed to reject van visit');
    }
  };

  const handleAccept = async (vanVisitId) => {
    try {
      await axios.post(`/api/van-visits/${vanVisitId}/accept`);
      message.success('Van visit accepted');
      setVanVisits(vanVisits.filter(vanVisit => vanVisit._id !== vanVisitId));
    } catch (error) {
      message.error('Failed to accept van visit');
    }
  };

  if (loading) {
    return <Spin />;
  }

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
      {vanVisits.map(vanVisit => (
        <Card
          key={vanVisit._id}
          title={vanVisit.visitTitle}
          style={{ width: 300 }}
          actions={[
            <Button onClick={() => handleReview(vanVisit._id)}>Review</Button>,
            <Button onClick={() => handleReject(vanVisit._id)} danger>Reject</Button>,
            <Button onClick={() => handleAccept(vanVisit._id)} type="primary">Accept</Button>
          ]}
        >
          <p>{vanVisit.description}</p>
        </Card>
      ))}
    </div>
  );
};

export default SubmittedVanVisits;
