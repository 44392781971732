import axios from 'axios';
import configs from "../../lib/config";

const apiBaseUrl = configs.apiBaseUrl;

// Create a new phase module
export const createPhaseModule = async (phaseModuleData) => {
  try {
    const response = await axios.post(`${apiBaseUrl}/status/phase-modules`, phaseModuleData);
    return response.data;
  } catch (error) {
    console.error('Error creating phase module:', error);
    throw error;
  }
};

// Get all phase modules
export const getPhaseModules = async () => {
  try {
    const response = await axios.get(`${apiBaseUrl}/status/phase-modules`);
    return response.data;
  } catch (error) {
    console.error('Error getting phase modules:', error);
    throw error;
  }
};

// Get phase module by ID
export const getPhaseModuleById = async (phaseModuleId) => {
  try {
    const response = await axios.get(`${apiBaseUrl}/status/phase-modules/${phaseModuleId}`);
    return response.data;
  } catch (error) {
    console.error('Error getting phase module by ID:', error);
    throw error;
  }
};

// Update phase module by ID
export const updatePhaseModule = async (phaseModuleId, phaseModuleData) => {
  try {
    const response = await axios.put(`${apiBaseUrl}/status/phase-modules/${phaseModuleId}`, phaseModuleData);
    return response.data;
  } catch (error) {
    console.error('Error updating phase module:', error);
    throw error;
  }
};

// Delete phase module by ID
export const deletePhaseModule = async (phaseModuleId) => {
  try {
    const response = await axios.delete(`${apiBaseUrl}/status/phase-modules/${phaseModuleId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting phase module:', error);
    throw error;
  }
};

// Get phase modules by phase
export const getPhaseModulesByPhase = async (phase) => {
  try {
    const response = await axios.get(`${apiBaseUrl}/status/phase-modules/phase/${phase}`);
    return response.data;
  } catch (error) {
    console.error('Error getting phase modules by phase:', error);
    throw error;
  }
};

// Check school phase status
export const checkPhaseStatus = async (schoolId) => {
  try {
    const response = await axios.get(`${apiBaseUrl}/status/checkSchoolPhase/${schoolId}`);
    return response.data;
  } catch (error) {
    console.error('Error checking school phase status:', error);
    throw error;
  }
};
