import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Statistic, List, Avatar, message } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined, BellOutlined } from '@ant-design/icons';
import { getUsers } from '../context/dbTransactions/users';
import { getSchools } from '../context/dbTransactions/school';
import { getPhaseModules } from '../context/dbTransactions/phaseModule';

const Dashboard = () => {
  const [notifications, ] = useState([
    { title: 'New user registered', description: 'A new user has registered on the platform.' },
    { title: 'Server downtime', description: 'The server was down for 2 hours.' },
    { title: 'New comment', description: 'A new comment was added to your post.' },
  ]);
  const [userData, setUserData] = useState({ totalUsers: 0, activeUsers: 0 });
  const [schoolData, setSchoolData] = useState({ totalSchools: 0, pendingSchools: 0, approvedSchools: 0 });
  const [phaseData, setPhaseData] = useState({ totalPhases: 0, completedPhases: 0 });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const users = await getUsers();
        const schools = await getSchools();
        const phases = await getPhaseModules();

        setUserData({
          totalUsers: users.length,
          activeUsers: users.filter(user => user.isActive).length,
        });

        setSchoolData({
          totalSchools: schools.length,
          pendingSchools: schools.filter(school => school.verification_status === 'pending').length,
          approvedSchools: schools.filter(school => school.verification_status === 'approved').length,
        });

        setPhaseData({
          totalPhases: phases.length,
          completedPhases: phases.filter(phase => phase.status === 'completed').length,
        });
      } catch (error) {
        message.error('Failed to fetch data.');
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div style={{ padding: '24px' }}>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} lg={8}>
          <Card title="User Data" bordered={false}>
            <Statistic
              title="Total Users"
              value={userData.length}
              prefix={<ArrowUpOutlined />}
              valueStyle={{ color: '#3f8600' }}
            />
            <Statistic
              title="Active Users"
              value={userData.activeUsers}
              prefix={<ArrowUpOutlined />}
              valueStyle={{ color: '#3f8600' }}
              style={{ marginTop: 16 }}
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} lg={8}>
          <Card title="School Analytics" bordered={false}>
            <Statistic
              title="Total Schools"
              value={schoolData.length}
              prefix={<ArrowUpOutlined />}
              valueStyle={{ color: '#3f8600' }}
            />
            <Statistic
              title="Pending Schools"
              value={schoolData.pendingSchools}
              prefix={<ArrowDownOutlined />}
              valueStyle={{ color: '#cf1322' }}
              style={{ marginTop: 16 }}
            />
            <Statistic
              title="Approved Schools"
              value={schoolData.approvedSchools}
              prefix={<ArrowUpOutlined />}
              valueStyle={{ color: '#3f8600' }}
              style={{ marginTop: 16 }}
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} lg={8}>
          <Card title="School Phase Analytics" bordered={false}>
            <Statistic
              title="Total Phases"
              value={phaseData.totalPhases}
              prefix={<ArrowUpOutlined />}
              valueStyle={{ color: '#3f8600' }}
            />
            <Statistic
              title="Completed Phases"
              value={phaseData.completedPhases}
              prefix={<ArrowUpOutlined />}
              valueStyle={{ color: '#3f8600' }}
              style={{ marginTop: 16 }}
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} lg={8}>
          <Card title="Notifications" bordered={false}>
            <List
              itemLayout="horizontal"
              dataSource={notifications}
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<Avatar icon={<BellOutlined />} />}
                    title={item.title}
                    description={item.description}
                  />
                </List.Item>
              )}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
