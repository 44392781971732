import React from 'react';
import { Table, Card } from 'antd';

const extractYouTubeVideoId = (url) => {
  const regex = /(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = url.match(regex);
  return match ? match[1] : null;
};

const ViewProject = ({ project }) => {
  if (!project) return null;

  const dataSource = [
    { key: 'projectThumbnail', value: project.projectThumbnail },
    { key: 'isSubmissionProject', value: project.isSubmissionProject ? 'Yes' : 'No' },
    { key: 'description', value: project.description },
    { key: 'code', value: project.code },
    { key: 'type', value: project.type },
    { key: 'status', value: project.status },
    { key: 'projectVideoLink', value: project.projectVideoLink }
  ];

  const columns = [
    {
      title: 'Field',
      dataIndex: 'key',
      key: 'key',
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      render: (text, record) => {
        if (record.key === 'projectThumbnail' && text) {
          return <img src={text} alt="Project Thumbnail" style={{ maxWidth: '100%' }} />;
        }
        if (record.key === 'description' && text) {
          return <div dangerouslySetInnerHTML={{ __html: text }} />;
        }
        if (record.key === 'projectVideoLink' && text) {
          const videoId = extractYouTubeVideoId(text);
          if (videoId) {
            const embedUrl = `https://www.youtube.com/embed/${videoId}`;
            return <iframe title="Project Video" width="100%" height="400" src={embedUrl} frameBorder="0" allowFullScreen></iframe>;
          }
        }
        return text;
      }
    }
  ];

  return (
      <Card title="Project Details" style={{ maxWidth: '800px', margin: 'auto' }}>
        <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            showHeader={false}
            bordered
        />
      </Card>
  );
};

export default ViewProject;
