import React, { useEffect, useState } from 'react';
import { Tabs, Select, Spin, message } from 'antd';
import SubmittedProjects from '../components/submissions/submittedProjects';
import SubmittedVanVisits from '../components/submissions/submittedVanVisits';
import { getSchools } from '../context/dbTransactions/school';

const { TabPane } = Tabs;
const { Option } = Select;

const Submissions = () => {
  const [schools, setSchools] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSchools = async () => {
      try {
        const schoolsData = await getSchools();
        setSchools(schoolsData);
      } catch (error) {
        message.error('Failed to fetch schools');
      } finally {
        setLoading(false);
      }
    };

    fetchSchools();
  }, []);

  const handleSchoolChange = (value) => {
    setSelectedSchool(value);
  };

  if (loading) {
    return <Spin />;
  }

  return (
    <div>
      <Select
        placeholder="Select a school"
        style={{ width: 200, marginBottom: 16 }}
        onChange={handleSchoolChange}
      >
        {schools.map(school => (
          <Option key={school._id} value={school._id}>
            {school.school_name}
          </Option>
        ))}
      </Select>
      {selectedSchool && (
        <Tabs defaultActiveKey="1">
          <TabPane tab="Project Submissions" key="1">
            <SubmittedProjects schoolId={selectedSchool} />
          </TabPane>
          <TabPane tab="Van Visits Submissions" key="2">
            <SubmittedVanVisits schoolId={selectedSchool} />
          </TabPane>
        </Tabs>
      )}
    </div>
  );
};

export default Submissions;
