import axios from 'axios';
import configs from "../../lib/config";

const apiBaseUrl = configs.apiBaseUrl;

// Create a new user
export const createUser = async (userData) => {
  try {
    const response = await axios.post(`${apiBaseUrl}/users`, userData);
    return response.data;
  } catch (error) {
    console.error('Error creating user:', error);
    throw error;
  }
};

// Get all users
export const getUsers = async () => {
  try {
    const response = await axios.get(`${apiBaseUrl}/users`);
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

// Get user by ID
export const getUserById = async (userId) => {
  try {
    const response = await axios.get(`${apiBaseUrl}/users/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching user by ID:', error);
    throw error;
  }
};

// Update user by ID
export const updateUser = async (userId, userData) => {
  try {
    const response = await axios.put(`${apiBaseUrl}/users/${userId}`, userData);
    return response.data;
  } catch (error) {
    console.error('Error updating user:', error);
    throw error;
  }
};

// Delete user by ID
export const deleteUser = async (userId) => {
  try {
    const response = await axios.delete(`${apiBaseUrl}/users/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting user:', error);
    throw error;
  }
};

// Login user
export const loginUser = async (loginData) => {
  try {
    const response = await axios.post(`${apiBaseUrl}/users/login`, loginData);
    return response.data;
  } catch (error) {
    console.error('Error logging in user:', error);
    throw error;
  }
};

// Request password reset
export const requestPasswordReset = async (email) => {
  try {
    const response = await axios.post(`${apiBaseUrl}/users/request-password-reset`, { email });
    return response.data;
  } catch (error) {
    console.error('Error requesting password reset:', error);
    throw error;
  }
};

// Update password with OTP validation
export const updatePassword = async (passwordData) => {
  try {
    const response = await axios.post(`${apiBaseUrl}/users/update-password`, passwordData);
    return response.data;
  } catch (error) {
    console.error('Error updating password:', error);
    throw error;
  }
};
