import React, { useEffect, useState } from 'react';
import { Card, Button, message, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { getSubmissionsBySchool } from '../../context/dbTransactions/submission';
import axios from "axios";

const SubmittedProjects = ({ schoolId }) => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await getSubmissionsBySchool(schoolId);
        setProjects(response);
      } catch (error) {
        message.error('Failed to fetch projects');
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, [schoolId]);

  const handleReview = (projectId) => {
    navigate(`/portal/projects/${projectId}`);
  };

  const handleReject = async (projectId) => {
    try {
      await axios.post(`/api/submissions/${projectId}/reject`);
      message.success('Project rejected');
      setProjects(projects.filter(project => project._id !== projectId));
    } catch (error) {
      message.error('Failed to reject project');
    }
  };

  const handleAccept = async (projectId) => {
    try {
      await axios.post(`/api/submissions/${projectId}/accept`);
      message.success('Project accepted');
      setProjects(projects.filter(project => project._id !== projectId));
    } catch (error) {
      message.error('Failed to accept project');
    }
  };

  if (loading) {
    return <Spin />;
  }

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
      {projects.map(project => (
        <Card
          key={project._id}
          title={project.projectTitle}
          style={{ width: 300 }}
          actions={[
            <Button onClick={() => handleReview(project._id)}>Review</Button>,
            <Button onClick={() => handleReject(project._id)} danger>Reject</Button>,
            <Button onClick={() => handleAccept(project._id)} type="primary">Accept</Button>
          ]}
        >
          <p>{project.description}</p>
        </Card>
      ))}
    </div>
  );
};

export default SubmittedProjects;
